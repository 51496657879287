import Q from 'q'
//请求二级地区，1为国内，2为国外
import web_common_area_getAreaDataByAreaTypeResponse from './web_common_area_getAreaDataByAreaTypeResponse';
//请求三级地区，根据二级地区的id
import web_common_area_getInlandAreaChildListByParentIdResponse from './web_common_area_getInlandAreaChildListByParentIdResponse';


export default {
  props: {
    fromCitys: Number|String,
  },
  data() {
    return {
        fromProvince: '',
        fromCity: '',
      allocation: {
        fromProvince: [],
        fromCity: [],
      }
    }
  },
  methods: {
    //请求国内省份
    req_from_province_list (areaType) {
      return Q.when()
        .then(function () {
          return web_common_area_getAreaDataByAreaTypeResponse(areaType);
        })
    },
    //请求国内城市
    req_from_city_list (id) {
      return Q.when()
        .then(function () {
          return web_common_area_getInlandAreaChildListByParentIdResponse(id);
        })
    },
    //进来第一次请求国内城市(出发)
    inland_province () {
      const _this = this;
      const areaType = {areaType: 1}
      Q.when()
        .then(function () {
          return _this.req_from_province_list(areaType)
        })
        .then(function (res) {
          _this.allocation.fromProvince = res.areaTreeResult;
        })
    },
    //改变出发城市
    inland_city (id) {
       const _this = this;
      const params = {id};
      Q.when()
        .then(function () {
          return _this.req_from_city_list(params);
        })
        .then(function (res) {
          _this.allocation.fromCity = res.areaTreeResult;
        })
    },
  },
  watch: {
    fromCitys: function (newVal) {
      if (newVal === -1) {
        this.fromProvince = '';
        this.fromCity = '';
      }
    },
    //监听出发省份，更改出发城市
    fromProvince: function (newVal) {
      this.fromCity = '';
      this.inland_city(newVal)
    },
    fromCity: function (newVal) {
      this.$emit('onEmitFromCity',newVal);
    },
  },
  computed: {

  },
  mounted() {
    this.inland_province();
  }
}
