const Q = require(`q`);
const moment = require(`moment`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 接口文档地址：http://192.168.0.15:8080/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/buyer-tour-cut-order-controller/getBuyerTourCutOrderListUsingPOST
const web_common_buyerTourCutOrder_orderList = (pParameter) => {
  if (!pParameter) pParameter = {};
  if (pParameter.teamStart) pParameter.teamStart = moment(pParameter.teamStart).format('YYYY-MM-DD')
  if (pParameter.teamEnd) pParameter.teamEnd = moment(pParameter.teamEnd).format('YYYY-MM-DD')
  if (pParameter.orderDateStart) pParameter.orderDateStart = moment(pParameter.orderDateStart).format('YYYY-MM-DD')
  if (pParameter.orderDateEnd) pParameter.orderDateEnd = moment(pParameter.orderDateEnd).format('YYYY-MM-DD')

  const p = {
    method: 'post',
    urlSuffix: '/web/common/buyerTourCutOrder/orderList',
    data: pParameter,

  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_common_buyerTourCutOrder_orderList;
