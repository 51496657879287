
//国际城市
/**
 * 文档地址：http://192.168.0.15:8080/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/area-controller/getSecondAreaDataByAreaTypeUsingPOST
 */
const Q = require(`q`);
const _ = require(`underscore`);

const __request = require('@/lib/data-service/default/__request');

// 接口文档地址：
// http://192.168.0.15:8080/doc.html#/%E6%89%80%E6%9C%89%E6%8E%A5%E5%8F%A3/area-controller/getSecondAreaDataByAreaTypeUsingPOST
const web_common_area_getInternationalAreaChildListByParentIdResponse = (pParameter) => {

  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/web/common/area/getInternationalAreaChildListByParentIdResponse',
    data: pParameter,
    check_token: false,
  };

  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {

      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_common_area_getInternationalAreaChildListByParentIdResponse;
