//切位订单列表
import Q from 'q';
import web_common_buyerTourCutOrder_orderList from '@/lib/data-service/default/web_common_buyerTourCutOrder_orderList';
import web_common_buyerTourCutOrder_getOrderStatus from '@/lib/data-service/default/web_common_buyerTourCutOrder_getOrderStatus';
import web_common_buyerTourCutOrder_cancelAnOrder from '@/lib/data-service/default/web_common_buyerTourCutOrder_cancelAnOrder';
import fromCity from '@/component/fromCity/index.vue'
import toCity from '@/component/toCity/index.vue'
import { setTimeFormat } from '../../component/util'

export default {
  components: {
    fromCity,
    toCity
  },
  data() {
    return {
      teamList: '',
      orderDateList: '',
      statusActive: 0,
      form: {
        cutOrderNo: '',
        productNo: '',
        productName: '',
        productType: '',
        productAreasType: '',
        tripDay: '',
        teamStart: '',
        teamEnd: '',
        orderDateStart: '',
        orderDateEnd: '',
        buyerState: -1,
        currentPage: 1,
        pageSize: 8,
        fromCity: '',
        toCity: ''
      },
      setTimeFormat: setTimeFormat,
      allocation: {
        productType: [{
          label: '全部',
          value: ''
        },
        {
          label: '跟团游',
          value: 1
        },
        {
          label: '自由行',
          value: 2
        },
        {
          label: '当地游',
          value: 3
        }
        ],
        productAreasType: [{
          label: '全部',
          value: ''
        },
        {
          label: '国内',
          value: 1
        },
        {
          label: '出境',
          value: 2
        }
        ],
        tripDay: [{
          label: '全部',
          value: ''
        },
        {
          label: '1天',
          value: 1
        },
        {
          label: '2天',
          value: 2
        },
        {
          label: '3天',
          value: 3
        },
        {
          label: '4天',
          value: 4
        },
        {
          label: '5天',
          value: 5
        },
        {
          label: '6天',
          value: 6
        },
        {
          label: '7天',
          value: 7
        },
        {
          label: '8天以上',
          value: 8
        }
        ],
        buyerState: [],
      },
      cutOrderListData: [],
      pageAllocation: {
        currentPage: 1,
        totalCount: 0,
        pageSize: 8,
      },
      cancelOrderVisible: false,
      currentOrderNo: '',
      failureVisible: false,
      failReason: '',
    };
  },
  methods: {
    req_cut_order_nav() {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_buyerTourCutOrder_getOrderStatus();
        })
        .then(function (res) {
          _this.allocation.buyerState = res.resultStatus;
        })
    },
    req_cut_order_list(reqData) {
      const _this = this;
      Q.when()
        .then(function () {
          return web_common_buyerTourCutOrder_orderList(reqData);
        })
        .then(function (res) {
          _this.pageAllocation.totalCount = res.pageResult.totalCount;
          _this.pageAllocation.currentPage = res.pageResult.currentPage;
          _this.cutOrderListData = res.pageResult.pageData;
          _this.cutOrderListData.forEach(function (item) {
            item.teamTime = JSON.parse(item.teamTimes);
            item.affirm = item.platformBuyerAffirm.split('?');
            item.affirmUrl = item.affirm[0];
          })
        })
    },
    req_cancel_order(parmas) {
      return Q.when()
        .then(function () {
          return web_common_buyerTourCutOrder_cancelAnOrder(parmas)
        })
    },
    getRegiment(val) {
      if (!val) {
        this.form.teamStart = ''
        this.form.teamEnd = ''
      } else {
        this.form.teamStart = val[0]
        this.form.teamEnd = val[1]
      }
    },
    getPlaceOrder(val) {
      if (!val) {
        this.form.orderDateStart = ''
        this.form.orderDateEnd = ''
      } else {
        this.form.orderDateStart = val[0]
        this.form.orderDateEnd = val[1]
      }
    },
    search_order_list() {
      this.form.buyerState = -1;
      this.form.currentPage = 1;
      this.req_cut_order_list(this.form);
    },
    rest_search_data() {
      const _this = this;

      this.teamList = '';
      this.orderDateList = '';
      this.form = {
        cutOrderNo: '',
        productNo: '',
        productName: '',
        productType: '',
        productAreasType: '',
        tripDay: '',
        teamStart: '',
        teamEnd: '',
        orderDateStart: '',
        orderDateEnd: '',
        buyerState: -1,
        currentPage: 1,
        pageSize: 8,
        fromCity: -1,
        toCity: -1
      };
      setTimeout(function () {
        _this.form.fromCity = '';
        _this.form.toCity = '';
        _this.req_cut_order_list(this.form);
      }, 0)
    },
    select_status(index, status) {
      this.statusActive = index;
      this.form.buyerState = status;
      this.statusChange(this.form)
    },
    statusChange() {
      this.req_cut_order_list(this.form)
    },
    to_cut_order_detalis(cutOrderNo) {
      const query = {
        cutOrderNo
      };
      this.$router.push({
        name: 'distributor-tour-back-cut-order-details',
        query
      })
    },
    pageCurrent(page) {
      this.form.currentPage = page;
      this.req_cut_order_list(this.form)
    },
    onEmitFromCity(city) {
      this.form.fromCity = city;
    },
    onEmitToCity(city) {
      this.form.toCity = city;
    },
    alert_cancel_dialog(cutOrderNo) {
      this.$confirm('确定要取消订单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _this = this;
        Q.when()
          .then(function () {
            return _this.req_cancel_order({ cutOrderNo })
          })
          .then(function (res) {
            _this.$message({
              message: '订单取消成功',
              type: 'success',
              duration: 500
            });
            setTimeout(function () {
              _this.cancelOrderVisible = false;
              _this.req_cut_order_list(_this.form);
            }, 1000)
          })
      }).catch(() => {

      });
      // this.cancelOrderVisible = true;
      // this.currentOrderNo = cutOrderNo;
    },
    // set_cancel_order() {
    //   const _this = this;
    //   const parmas = {
    //     cutOrderNo: _this.currentOrderNo
    //   };
    //   Q.when()
    //     .then(function () {
    //       return _this.req_cancel_order(parmas)
    //     })
    //     .then(function (res) {
    //       _this.$message({
    //         message: '订单取消成功',
    //         type: 'success',
    //         duration: 500
    //       });
    //       setTimeout(function () {
    //         _this.cancelOrderVisible = false;
    //         _this.req_cut_order_list(_this.form);
    //       }, 1000)
    //     })
    // },
    to_cut_order_payment(id, cutOrderNo) {
      const query = {
        id,
        cutOrderNo,
        type: 'pay',
        payType: '1',
        cutOrder: 3,
        superior: 'distributor-tour-back-cut-order-list'
      };
      this.$router.push({
        name: 'distributor-tour-back-tour-order-detail',
        query,
        params: { refresh: 1 }
      })
    },
    alert_cause_failure(failReason) {
      this.failureVisible = true;
      this.failReason = failReason;
    },
    close_failure_alert() {
      this.failReason = '';
      this.failureVisible = false;
    },
  },
  watch: {
    form: {
      handler(newVal) {


        //<--格式化下单时间
        if (newVal.orderStartDate >= new Date()) {
          this.form.orderStartDate = new Date();
        }

        if (newVal.orderEndDate && newVal.orderStartDate >= newVal.orderEndDate) {
          this.form.orderStartDate = newVal.orderEndDate;
        }

        if (newVal.orderEndDate >= new Date()) {
          this.form.orderEndDate = new Date();
        }
        //格式化下单时间-->

        //<--格式化出团时间
        if (newVal.teamEnd && newVal.teamStart >= newVal.teamEnd) {
          this.form.teamStart = newVal.teamEnd;
        }
        //格式化出团时间-->
      },
      deep: true
    }
  },
  mounted() {
    this.req_cut_order_nav();
    this.req_cut_order_list(this.form);
  },
  activated() {
    this.req_cut_order_list(this.form);
  }
}
