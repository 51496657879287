import Q from 'q'
//请求二级地区，1为国内，2为国外
import web_common_area_getAreaDataByAreaTypeResponse from './web_common_area_getAreaDataByAreaTypeResponse';
//请求三级地区，根据二级地区的id
import web_common_area_getInlandAreaChildListByParentIdResponse from './web_common_area_getInlandAreaChildListByParentIdResponse';

//国际
import web_common_area_getInternationalAreaChildListByParentIdResponse from './web_common_area_getInternationalAreaChildListByParentIdResponse';


export default {
  props: {
    toCitys: Number|String,
  },
  data() {
    return {
        toAreaType: '',
        toDistrict: '',
        toProvince: '',
        toCity: '',
      allocation: {
        toAreaType: [{areaName: '国内', id: 100000}, {areaName: '国际', id: 0}],
        toDistrict: [],
        toProvince: [],
        toCity: [],
      }
    }
  },
  methods: {
    //请求国内城市
    req_from_city_list (id) {
      return Q.when()
        .then(function () {
          return web_common_area_getInlandAreaChildListByParentIdResponse(id);
        })
    },
    //请求国际列表
    req_international_city_list (id) {
      return Q.when()
        .then(function () {
          return web_common_area_getInternationalAreaChildListByParentIdResponse(id)
        })
    },
    // 请求目的地一级国内
    firster_inland (id) {
      const _this = this;
      const params = {id};
      Q.when()
        .then(function () {
          return _this.req_from_city_list(params);
        })
        .then(function (res) {
          _this.allocation.toDistrict = res.areaTreeResult;
        })
    },
  //  请求目的地一级国际
    firster_international (id) {
      const _this = this;
      const params = {id};
      Q.when()
        .then(function () {
          return _this.req_international_city_list(params)
        })
        .then(function (res) {
          _this.allocation.toDistrict = res.areaTreeResult;
        })
    },
    //二级国内
    second_inland (id) {
      const _this = this;
      const params = {id}
      Q.when()
        .then(function () {
          return _this.req_from_city_list(params)
        })
        .then(function (res) {
          _this.allocation.toProvince = res.areaTreeResult;
        })
    },
    // 二级国际
    second_international (id) {
      const _this = this;
      const params = {id}
      Q.when()
        .then(function () {
          return _this.req_international_city_list(params)
        })
        .then(function (res) {
          _this.allocation.toProvince = res.areaTreeResult;
        })
    },
    //三级国内
    thirdly_inland (id) {
      const _this = this;
      const params = {id}
      Q.when()
        .then(function () {
          return _this.req_from_city_list(params)
        })
        .then(function (res) {
          _this.allocation.toCity = res.areaTreeResult;
        })
    },
    // 三级国际
    thirdly_international (id) {
      const _this = this;
      const params = {id}
      Q.when()
        .then(function () {
          return _this.req_international_city_list(params)
        })
        .then(function (res) {
          _this.allocation.toCity = res.areaTreeResult;
        })
    }
  },
  watch: {
    toCitys: function (newVal) {
      if (newVal === -1) {
        this.allocation.toDistrict = []
        this.allocation.toProvince = []
        this.allocation.toCity = []
        this.toAreaType = '';
        this.toCity = '';
      }
    },
    //监听目的城市一级
    toAreaType: function (newVal) {
      this.toDistrict = '';
      switch (newVal) {
        case '':
          break;
        case 100000:
          this.firster_inland(newVal)
          break;
        case 0:
          this.firster_international(newVal);
          break;
      }
    },
    //监听目的城市二级
    toDistrict: function (newVal) {
      this.toProvince = '';
      if (this.toAreaType === 100000) {
        if (this.toDistrict === 900000) {
          this.allocation.toProvince = [{areaName: '钓鱼岛', id: 90000}];
          return;
        }
        this.second_inland(newVal)
      }
      if (this.toAreaType === 0) {
        this.second_international(newVal)
      }
    },
    //监听目的城市三级
    toProvince: function (newVal) {
      this.toCity = '';
      if (this.toAreaType === 100000) {
        if (this.toDistrict === 900000) {
          this.allocation.toCity = [{areaName: '全部', id: ''}, {areaName: '钓鱼岛', id: 90000}];
          return;
        }
        this.thirdly_inland(newVal)
      }
      if (this.toAreaType === 0) {
        this.thirdly_international(newVal)
      }
    },
    toCity: function (newVal) {
      this.$emit('onEmitToCity',newVal);
    }
  },
  computed: {

  },
}
